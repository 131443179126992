export class HttpRequestService {
    static async request(url, options) {
        const response = await fetch(url, options);
        if (!response.ok && response.status !== 404) {
            console.log(`HTTP error! status: ${response.status}`);
        }
        return response;
    }
    static async getRequest(url, queryParams) {
        return await this.request(createUrlWithParams(url, queryParams), {});
    }
    static async postJson(url, data) {
        return await this.request(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    }
    static async postSerializedData(url, serializedData) {
        return await this.request(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: serializedData
        });
    }
    static async postFormData(url, data) {
        return await this.request(url, {
            method: 'POST',
            body: data
        });
    }
    static async delete(url, data) {
        return await this.request(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    }
}
export function createUrlWithParams(url, data) {
    const urlObject = new URL(url, window.location.origin);
    urlObject.search = new URLSearchParams(data).toString();
    return urlObject.href;
}
